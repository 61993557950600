//base color
$white: #ffffff;
$red: #cb3a31;
$blue: #254e9c;
$yellow: #ebb105;
$purple: #7400cf;
$dark-blue: #2753a3;
$background: #f9f9f9;
$success: #43936c;
$line-stroke: #e4e8f1;
$subtle-grey: #8b99b4;
$subtle-black: #404e69;
$subtle-green: #d7ffec;
$subtle-red: #ffc8c5;
$subtle-blue: #e4f2fc;
$subtle-purple: #f5daff;
$subtle-yellow: #fff5e9;
