@import 'react-bootstrap-typeahead/css/Typeahead.css';
@import 'boxicons';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'bootstrap-daterangepicker/daterangepicker.css';
@import 'variable';
@import 'fontStyle';

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
  background: $subtle-black;
}
body {
  color: $subtle-black;
  background: $background;
}
label,
p,
h1,
h2,
h3,
h4,
h5,
a {
  color: #212121;
}
.fl_navbar {
  background: $white;
  height: 70px;
}
.fl_content {
  margin-left: 230px;
  padding: 85px 20px 20px 20px;
  transition: all 0.3s;
  &.full {
    margin-left: 0;
    transition: all 0.3s;
  }
  &.no-padding {
    padding: 66px 0px 0px 0px;
  }
}
.border-fl {
  border: 1px solid #eeeeee !important;
  border-radius: 6px !important;
}
.text-primary {
  color: #254e9c !important;
}
.text-dark {
  color: $subtle-black !important;
}
.text-blue {
  color: $blue !important;
}
.text-danger {
  color: $red !important;
}
.text-success {
  color: $success !important;
}
.fl-text-muted {
  color: #9e9e9e !important;
}
.fl-check-dis {
  .form-check-input:disabled,
  .form-check-input:disabled ~ .form-check-label,
  .form-check-input[disabled] ~ .form-check-label {
    opacity: 1;
  }
}
.bx {
  &.bx-big {
    font-size: 30px;
  }
}
.ic-menu {
  display: contents;
}
.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #9e9e9e;
}
.product-images {
  display: flex;
  .detail-product-images {
    display: flex;
    @media (max-width: 570px) {
      flex-direction: column;
    }
  }
  .image-container {
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    width: 60px;
    .count-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
    }
    .image {
      width: 60px;
      height: 60px;
      border-radius: 10px;
      border-color: #808080;
      margin-bottom: 8px;
      &.full-width {
        width: 100% !important;
        height: unset !important;
      }
    }
    .image-button {
      transition: all 0.2s ease-out;
      transform: translateY(-70%);
      opacity: 0;
      position: absolute;
      bottom: 8px;
      left: 0;
      width: 100%;
      height: 24px;
      background-color: rgba(29, 29, 29, 0.5);
      display: flex;
      justify-content: center;
      padding: 4px;
      border-radius: 0px 0px 10px 10px;
      .space {
        position: relative;
        top: -5px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 0;
        color: #ffffff;
        font-size: 14px;
      }

      .icon-show {
        color: #ffffff;
        font-size: 16px;
        cursor: pointer;
        position: relative;
        top: -5px;
      }

      .icon {
        color: #ffffff;
        font-size: 14px;
        cursor: pointer;
      }
    }

    &:hover {
      .image-button {
        opacity: 1;
        transition: all 0.2s ease;
        transform: translateY(0);
      }
    }
  }

  .box-images {
    margin-right: 10px;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    border-style: dashed;
    border-width: 2px;
    border-spacing: 10px;
    border-color: #808080;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    .icon {
      color: #808080;
      font-size: 24px;
      margin-top: 17px;
      display: flex;
      justify-content: center;
    }

    .without-text {
      color: #808080;
      font-size: 40px;
      margin-top: 17px;
      display: flex;
      justify-content: center;
    }

    &:hover {
      background-color: #e4e8f1;
    }
  }

  .text-info-images {
    max-width: 300px;

    .containt-text {
      font-size: 14px;
      color: #92a2bc;
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
}

.product-images-2 {

  .detail-product-images {
    display: flex;
    @media (max-width: 570px) {
      flex-direction: column;
    }
  }

  .image-container {
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    width: 60px;
    .count-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
    }

    .image {
      width: 60px;
      height: 60px;
      border-radius: 10px;
      border-color: #808080;
      margin-bottom: 8px;
      &.full-width {
        width: 100% !important;
        height: unset !important;
      }
    }

    .image-button {
      transition: all 0.2s ease-out;
      transform: translateY(-70%);
      opacity: 0;
      position: absolute;
      bottom: 8px;
      left: 0;
      width: 100%;
      height: 24px;
      background-color: rgba(29, 29, 29, 0.5);
      display: flex;
      justify-content: center;
      padding: 4px;
      border-radius: 0px 0px 10px 10px;

      .space {
        position: relative;
        top: -5px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 0;
        color: #ffffff;
        font-size: 14px;
      }

      .icon-show {
        color: #ffffff;
        font-size: 16px;
        cursor: pointer;
        position: relative;
        top: -5px;
      }

      .icon {
        color: #ffffff;
        font-size: 14px;
        cursor: pointer;
      }
    }

    &:hover {
      .image-button {
        opacity: 1;
        transition: all 0.2s ease;
        transform: translateY(0);
      }
    }
  }

  .box-images {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    border-radius: 10px;
    border-style: dashed;
    border-spacing: 10px;
    border-color: #808080;
    background-color: #EEEEEE;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }

    .icon {
      color: #808080;
      font-size: 24px;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    .without-text {
      color: #808080;
      font-size: 30px;
      margin-top: 26px;
      display: flex;
      justify-content: center;
    }

    .text-count{
      color: #808080;
      font-size: 10px;
      display: flex;
      justify-content: center;
      margin-right: 1px;
    }

    &:hover {
      background-color: #e4e8f1;
    }
  }

  .text-info-images {
    max-width: 300px;

    .containt-text {
      font-size: 14px;
      color: #92a2bc;
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
}

.tag {
  width: fit-content;
  background-color: #CFDFFF;
  border-radius: 8px;
  padding: 4px 8px;
  color: #3967BF;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.form-disable-secondary{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: 1px solid #e4e8f1;
  border-radius: 5px;
  background-color: #e9ecef;
  opacity: 1;
  margin-left: -1px;

  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
 
    .primary {
      color: #212121;
    }
    
    .secondary {
      color: #9E9E9E;
    }
}

.stock-distribution-list {
  p {
    margin-bottom: 0;
  }

  .logo-image-default {
    width: 90px;
    height: 90px;
    border-radius: 20px;
    background-color: #EEEEEE;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .label {
      font-size: 12px;
      color: #9E9E9E;
      margin-bottom: 0;
      text-align: center;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    color: #9E9E9E
  }

  .content {
    font-size: 16px;
    font-weight: 600;
  }

}


.timeline {
  display: flex;
  .timeline-object {
    width: 20px;
    height: 100%;
    .timeline-dot {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        height: 12px;
        width: 12px;
        background-color: $blue;
        border-radius: 100%;
        margin-left: 3px;
        margin-top: 5px;
      }
      &::after {
        position: absolute;
        content: '';
        height: 18px;
        width: 18px;
        border-radius: 100%;
        color: white;
        display: flex;
        align-items: center;
        border: 1.5px solid $blue;
        margin-top: 2px;
      }
    }
    .timeline-line {
      width: 4px;
      height: 100%;
      content: '';
      margin-left: 7px;
      margin-top: 20px;
      background-color: #EEEEEE;
    }
  }

  .timeline-container {
    width: 90%;
    margin-left: 35px;
    
    &.without-timeline-object {
      margin-left: 0px;
    }
    .timeline-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      color: #212121;
      margin-top: 0;
      margin-bottom: 0;
    }
    .timeline-subtitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      color: #212121;
      margin-top: 0;
      margin-bottom: 0;
    }
    .timeline-paragraph {
      font-size: 10px;
      font-weight: 400;
      line-height: 150%;
      color: #9E9E9E;
      margin-top: 0;
      margin-bottom: 0;
    }
    .timeline-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      color: #9E9E9E;
      margin-top: 0;
      margin-bottom: 0;
    }
    
    .image-container {
      position: relative;
      overflow: hidden;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      width: 60px;
      .count-title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
      }
      .image {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        border-color: #808080;
        margin-bottom: 8px;
        &.full-width {
          width: 100% !important;
          height: unset !important;
        }
      }
      .image-button {
        transition: all 0.2s ease-out;
        transform: translateY(-70%);
        opacity: 0;
        position: absolute;
        bottom: 8px;
        left: 0;
        width: 100%;
        height: 24px;
        background-color: rgba(29, 29, 29, 0.5);
        display: flex;
        justify-content: center;
        padding: 4px;
        border-radius: 0px 0px 10px 10px;
        .space {
          position: relative;
          top: -5px;
          margin-left: 5px;
          margin-right: 5px;
          margin-bottom: 0;
          color: #ffffff;
          font-size: 14px;
        }
        .icon-show {
          color: #ffffff;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          top: -5px;
        }
        .icon {
          color: #ffffff;
          font-size: 14px;
          cursor: pointer;
        }
      }
      &:hover {
        .image-button {
          opacity: 1;
          transition: all 0.2s ease;
          transform: translateY(0);
        }
      }
    }
  }
}

.text-optional {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #9e9e9e;
  margin-bottom: 0px;
}
.input-table,
.dropdown-l {
  .rbt-menu {
    &.dropdown-menu {
      &.show {
        width: 330px !important;
      }
    }
  }
}

.upload-images-title {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.upload-images {
  display: flex;
  justify-content: center;
  .modal-img {
    video {
      width: 100%;
    }
  }
  .box {
    width: 100%;
    border-radius: 10px;
    border-style: dashed;
    border-width: 2px;
    border-spacing: 10px;
    border-color: #d3d3d3;
    background-color: #f8f7fc;
    text-align: center;

    .loading {
      font-size: 30px;
      margin-top: 100px;
    }

    .icon {
      font-size: 50px;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .text-medium {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 0;
      color: #404e69;
    }

    .text-small {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0;
      color: #7485a6;
    }

    .button {
      margin-top: 30px;
    }
  }
}

.upload-images-result {
  margin-top: 20px;

  .title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .images-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .images {
      display: flex;
      justify-content: center;

      .image {
        width: 35px;
        height: 35px;
        border-radius: 5px;
      }

      .name {
        font-size: 14px;
        margin-left: 10px;
        margin-bottom: 0;
        align-self: center;
        color: #1890ff;
        text-decoration: unset;
      }
    }
    .retry,
    .delete {
      // color: #FF3B2F;
      color: #92a2bc;

      align-self: center;
      font-size: 20px;
    }

    .file {
      align-self: center;
      font-size: 20px;
    }

    &:hover {
      background-color: #f6f6f6;
    }
  }
}

.card-product {
  background-color: white;

  .card-product-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  }

  .card-product-body {
    padding: 24px;
  }
}
#reportrange {
  border: 1px solid #e4e8f1 !important;
}
.no-images {
  &.size-60 {
    width: 60px;
    height: 60px;
    i {
      font-size: 28px;
      margin: 0;
      padding: 0;
    }
    p {
      font-size: 8px;
      margin: 0;
      padding: 0;
    }
  }
  border: 1px solid #e1e1e1;
  border-radius: 4.21873px;
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  i {
    font-size: 40px;
    margin: 0;
    padding: 0;
  }
  p {
    font-size: 10px;
    margin: 0;
    padding: 0;
  }
}
.fl-card-transfer {
  padding: 16px;
  border: 1px solid #e4e8f1;
  border-radius: 6px;

  p,
  h5 {
    margin-bottom: 2px;
  }
  &__header {
    display: flex;
    padding-bottom: 11px;
    border-bottom: 1px solid #e1e1e1;
    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
    }
    span {
      padding: 0 8px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      span {
        font-weight: 500;
      }
    }
    &_m {
      display: none;
    }
    &__status {
      margin-left: auto;
    }
  }
  &__body {
    padding: 15px 0px;
    display: flex;
    border-bottom: 1px solid #e1e1e1;
    &__images {
      margin-right: 20px;
      position: relative;
      height: 100%;
      .no-images,
      img {
        border: 1px solid #e1e1e1;
        border-radius: 4.21873px;
        width: 80px;
        height: 80px;
      }
      .preview-icon {
        border-radius: 4.21873px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(29, 29, 29, 0.5);
        opacity: 0;
        transition: all 0.4s ease-out;
        i {
          cursor: pointer;
          font-size: 30px;
          color: white;
          transition: all 0.3s ease-in;
          transform: translateY(100%);
        }
        &:hover {
          opacity: 1;
          transition: all 0.1s ease;
          i {
            transition: all 0.3s ease-out;
            transform: translateY(0);
          }
        }
      }
    }
    &__desc {
      width: 100%;
      font-size: 14px;
      line-height: 150%;
      h5 {
        font-weight: 500;
        font-size: 18px;
        color: #404e69;
      }
      p {
        font-weight: 400;
        color: #9da5b6;
        span {
          &.blue {
            color: #254e9c;
          }
        }
      }
      &__received {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  &__footer {
    padding: 15px 0px;
    &__desc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__l {
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #9e9e9e;
        }
      }
      &__r {
        line-height: 150%;
        font-weight: 400;
        color: #404e69;
        text-align: end;
        p {
          font-size: 16px;
          span {
            font-weight: 500;
            font-size: 20px;
            &.blue {
              color: #254e9c;
            }
          }
        }
      }
    }
    &__action {
      margin-top: 15px;
      display: flex;
      align-items: center;
    }
  }
}
.fl-button {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  padding: 8px 16px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  background: white;
  transition: all 0.2s ease;
  &:hover {
    background: #eeeeee;
  }
  &-action {
    color: #9e9e9e;
  }
  &-blue {
    &:hover,
    &.fl-button-blue.active {
      background: #254e9c;
      color: white;
      transition: all 0.2s ease;
    }
  }
  &-blue:disabled,
  &-blue:hover {
    color: #9e9e9e;
    background: #eeeeee;
  }
}
.fl-btn-v2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  padding: 10px 16px;

  &.btn.btn-outline-primary {
    border-color: #e4e8f1;
    &:hover {
      color: white;
    }
  }
  &.btn.btn-outline-dark {
    border-color: #e4e8f1;
    color: #404e69;

    &:hover,
    &.active {
      color: white;
      background-color: #404e69;
    }
  }
}

.capture-images {
  width: 100%;
}

.crop {
  height: 360px;

  .crop-title {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }

  .cropper {
    margin-top: 30px;
    height: 200px;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 40px;
  }

  .range {
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 60px;
    left: 52%;
    align-items: center;
    transform: translateX(-50%);
    display: flex;
  }

  .crop-button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
  }
}

.carousel-indicators [data-bs-target] {
  opacity: 1;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white;
}
.carousel-indicators .active {
  opacity: 1;
  background-color: #254e9c;
}

.form-select,
.form-control {
  color: $subtle-black;
  border: 1px solid #e4e8f1;
}
.form-select::placeholder {
  color: #9e9e9e !important;
}
.form-control {
  border-radius: 5px;
}
.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #9e9e9e;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #9e9e9e;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #9e9e9e;
}
.form-check-input {
  border: 3px solid $line-stroke;
}
.form-check-input[type='radio'] {
  border: 3px solid $subtle-black;
}
.form-check-input:checked[type='radio'] {
  position: relative;
  background-image: unset;
  background-color: $white;
}
.form-check-input:checked[type='radio']::before {
  content: '';
  position: absolute;
  width: 65%;
  height: 65%;
  background-color: $subtle-black;
  margin: auto;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
}
.btn {
  border-radius: 5px;
  // color: $subtle-black;
}
.btn-success {
  background-color: $success;
  border-color: $success;
}
.btn-danger {
  background-color: #ff3b2f;
}

.btn-outline-danger {
  color: #ff3b2f;
  background-color: white;
  &:hover {
    background-color: #ff3b2f;
  }
}
.btn.btn-outline-primary {
  color: #3967bf;
  border-color: #3967bf;
  &:hover {
    background-color: #3967bf;
    color: white;
  }
}
.btn-fl-act {
  button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }
  .btn-outline-primary {
    border: 1px solid #e4e8f1;
  }
}
.delete-cont {
  position: absolute;
  right: 30px;
  .fl-btn-delete {
    padding: 12px 40px;
    font-weight: 700;
    border: 1px solid #e4e8f1;
  }
}
.btn.fl_btn,
.btn-light.fl_btn {
  border: 1px solid $line-stroke;
  background-color: $white;
  color: $subtle-black;
  &:hover,
  &:focus {
    background-color: $line-stroke;
  }
  &.active {
    background-color: $line-stroke;
  }
}
.table {
  color: $subtle-black;
}
.card {
  border: 1px solid #eeeeee;
  border-radius: 8px;
}
.card-body {
  padding: 24px;
  
  &.small {
    padding: 1rem 0.5rem;
  }
}
.label-section {
  font-weight: 600;
  font-size: 20px;
  color: #9e9e9e;

  &-sub {
    @extend .label-section;
    font-size: 18px;
  }
}
.fl_dropdown_search {
  position: relative;
  &.fl_filter_btn {
    button.dropdown-toggle {
      width: 100%;
      padding: 0.375rem 2.25rem 0.375rem 0.75rem;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e');
      background-size: 16px 12px;
      background-position: right 0.75rem center;
    }
  }
  button.dropdown-toggle {
    position: relative;
    text-align: left;
    width: 100%;
    background-color: $white;
    border-color: $line-stroke;
    color: $subtle-black;
    overflow: hidden;
    text-overflow: ellipsis;
    &::after {
      position: absolute;
      display: none;
      top: 50%;
      transform: translate(-50%, -50%);
      right: 0;
    }
    &[aria-expanded='true'] {
      background-color: $line-stroke;
      border-color: $line-stroke;
      color: $subtle-black;
    }
    &:hover,
    &:focus {
      background-color: $white;
      color: $subtle-black;
    }
  }
  div[aria-labelledby='dropdown-basic-button'] {
    width: 100%;
  }
  &__search {
    padding: 10px;
  }
  &__content {
    overflow: auto;
    max-height: 250px;
  }
}

.fw-700 {
  font-weight: 700;
}
.fl_icon_popover {
  cursor: pointer;
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid rgba(78, 102, 145, 0.6);
  margin-left: -10px;
  i {
    top: -1px;
    width: 11px;
    height: 11px;
    left: -1px;
  }
}
.fl_filter_content {
  position: absolute;
  width: 500px;
  right: 0;
  &__child {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: inherit;
    text-align: end;
    &__dropdown {
      display: flex;
    }
  }
}
.fl-btn-clear {
  // padding: 0;
  font-size: 16px;
  font-weight: 700;
  padding: 4px 16px;
  background: #254e9c;
  border-color: #254e9c;
  color: #ffffff;
  border-radius: 8px;
  text-decoration: none;
  height: fit-content;
  &:hover {
    background: #3967bf;
    color: #ffffff;
    border-color: #254e9c;
    box-shadow: unset;
  }
  &:focus {
    box-shadow: unset;
  }
}
.modal-backdrop {
  background-color: $subtle-black;
  &.show {
    opacity: 0.7;
  }
}
.fl_modal {
  .modal-content {
    padding: 12px;
    border-radius: 6px;
    border-width: 0;
    box-shadow: 0px 0px 10px 1px rgba(74, 145, 225, 0.1);
  }
  .modal-header {
    border-bottom: unset;
  }
  .modal-footer {
    border-top: unset;
    // justify-content: center;
    .btn {
      padding: 13px 33px;
    }
  }
  &__ {
    .btn-close {
      display: none;
    }
  }
}
.fl-modal-v2 {
  .modal-content {
    width: 470px;
    max-height: 360px;
  }
  .modal-header {
    font-size: 16px;
    padding-top: 48px;
    border-bottom: unset;
  }
  .modal-body {
    font-size: 14px;
  }
  .modal-body,
  .modal-footer,
  .modal-header {
    padding-left: 32px;
    padding-right: 32px;
  }
  .modal-footer {
    border-top: unset;
    display: flex;
    flex-wrap: unset;
    padding-bottom: 48px;
    button {
      width: 50%;
    }
  }
  .fl-btn-close {
    color: $subtle-black;
    font-weight: 600;
    font-size: 16px;
    background-color: white;
    border: 1px solid #e4e8f1;
    &:hover {
      background-color: #f1f2f4;
    }
  }
  .modal-lg,
  .modal-xl {
    @media (min-width: 992px) {
      max-width: 470px;
    }
  }
}

.dropdown-item:has(.fl_item_active[active='true']) {
  background-color: $blue;
}
input.form-control:disabled,
.form-control[readonly] {
  // background: #f5f5f5;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #212121;
}
input.input-danger:disabled {
  background-color: $subtle-red;
  color: $red;
  text-align: center;
  border-width: 0;
}
input.input-success:disabled {
  background-color: $subtle-green;
  color: $success;
  text-align: center;
  border-width: 0;
}
.btn-primary {
  background-color: #254e9c;
  border-color: #254e9c;
}
.cursor-pointer {
  cursor: pointer;
}
.module-group-view {
  .form-check-input:disabled ~ .form-check-label,
  .form-check-input[disabled] ~ .form-check-label {
    opacity: unset;
  }
}
.fl_dropdown_check {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  max-height: 250px;
  overflow: auto;
  .form-check-input {
    border: 3px solid #808080;
    color: #808080;
  }
}
.label-draft {
  padding: 5px 14.7px 5px 29.5px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  width: unset;
}
.fl_switch.active {
  background-color: $subtle-green;
  color: $success;
  &.form-switch .form-check-input:checked {
    background-color: $success;
    border-color: 0;
  }
}
.fl_switch.non-active {
  background-color: $subtle-red;
  color: $red;
  &.form-switch .form-check-input {
    background-color: $red;
    border-color: 0;
  }
}
.fl_switch {
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500;
  font-size: 1vw;
  padding: 6px 10px 6px 40px;
  & > label {
    cursor: pointer;
  }
  .form-check-input {
    margin-right: 5px;
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    border: 2px solid rgba(0, 0, 0, 0.25);
  }
  &.form-switch .form-check-input {
    background-color: #e4e8f1;
    border-color: #e4e8f1;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27white%27/%3e%3c/svg%3e');
  }
}

.fl_switch2 {
  display: inline-block;

  .form-check-input {
    width: 3em;
    height: 1.5em;
    margin-right: 5px;
    cursor: pointer;
  }
}

.popover-fl-di {
  min-width: 315px;
  padding: 15px;
  p {
    font-size: 12px;
    margin: 0;
  }
}
.fl-bg-yellow {
  background-color: $subtle-yellow !important;
}

.multiple-Upload {
  .multiple-Item {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    &:hover {
      background-color: #f6f6f6;
      i.delete-upload.success {
        display: block;
        color: #404e69;
      }
    }
    label.error {
      color: $red;
    }
    label.success {
      color: $success;
    }
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 5px;
    }
    i.delete-upload {
      cursor: pointer;
      margin-left: auto;
      margin-right: 10px;
      display: none;
      &.error {
        color: $red;
        display: block;
      }
      &.success {
        color: $success;
      }
    }
  }
}

.collapse-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}

.filter-map {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  display: flex;
  .filter-map-button {
    height: 50px;
  }
}


.tooltip-info {
  cursor: pointer;

  i {
    color: #9E9E9E;
  }
}

.marker-icon {
  position: absolute;
  top: -43px;
  left: -15px;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  img {
    border-radius: 100%;
    z-index: 3;
  }
  &::before {
    position: absolute;
    content: '';
    height: 30px;
    width: 30px;
    background-color: $white;
    border-radius: 100%;
    margin: auto;
    z-index: 1;
  }
  &::after {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    background-color: $blue;
    transform: rotate(45deg);
    top: 24px;
  }
}

.rbt-loader {
  display: none;
  // position: absolute;
  // right: -26px;
}
.fl_backdrop-m,
.fl_backdrop {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100%;
  opacity: 0;
  z-index: 20;
  bottom: 0;
  right: 0;
  transition: all 0.3s;
  background-color: $subtle-black;
  &.filter {
    background-color: white;
    backdrop-filter: blur(5px);
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
  }
  &.hide {
    display: none !important;
  }
}

.fl_filter_limit {
  width: auto;
}
.fl_action {
  --bs-gutter-x: 0.5rem;
}
.result-filter-m {
  display: none;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 11px;
  color: #404e69;
  line-height: 12px;
  &-body {
    i {
      font-size: 14px;
    }
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    padding: 8px 5px;
    background: rgba(228, 232, 241, 0.5);
  }
}
.mobile-filter {
  display: none;
  position: fixed;
  bottom: 0;
  transform: translateY(100%);
  left: 0;
  right: 0;
  transition: all 0.3s;
  padding: 25px 20px 20px 20px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  height: 400px;
  width: 100vw;
  z-index: 21;
  background-color: $white;
  box-shadow: 0px -4px 30px -17px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: 0px -4px 30px -17px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 0px -4px 30px -17px rgba(0, 0, 0, 0.46);
  &::after {
    position: absolute;
    content: '';
    height: 6px;
    width: 20%;
    top: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    background-color: $line-stroke;
  }
  &-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    .btn-outline-secondary {
      border-color: $line-stroke;
    }
  }
  h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
  }
  p {
    margin: 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
  .btn-filter {
    border-radius: 16px;
  }
}

.text-unit {
  font-size: 11.5px;
  font-weight: 400;
}

.text-unit-right {
  text-align: right;
}

.rbt-menu.dropdown-menu {
  a {
    font-style: normal;
    color: $subtle-black;
    .text-title {
      font-weight: 700;
    }
    .text-desc {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
div[role='dialog'][aria-modal='true']:nth-last-child(1) {
  z-index: 1125;
}
.modal-backdrop.show:nth-last-child(2) {
  z-index: 1100;
}
div[role='dialog'][aria-modal='true']:nth-last-child(3) {
  z-index: 1075;
}
.modal-backdrop.show:nth-last-child(4) {
  z-index: 1050;
}
div[role='dialog'][aria-modal='true']:nth-last-child(5) {
  z-index: 1025;
}
.modal-backdrop.show:nth-last-child(6) {
  z-index: 1000;
}
.modal-qr .modal-content {
  padding: 0 !important;
}
.modal-b2b {
  hr {
    margin: 0.3rem 0;
  }
  h5 {
    font-size: 14px;
    font-weight: 500;
  }
  p {
    margin-bottom: 0.1rem;
    font-size: 16px;
    font-weight: 700;
  }
}
.fl-title-detail {
  display: flex;
  align-items: center;
}
.ic-breadcrumbs {
  color: #92a2bc;
  font-size: 34px;
}
.label-required {
  &::before {
    content: '*';
    font-size: 12px;
    color: $red;
    margin-right: 3px;
  }
}

#mobileTable {
  overflow: auto;
}

#mobileTablePembelian {
  overflow: auto;
}

.status-label-m {
  display: none;
}

.fl-tabs {
  &.nav-tabs {
    margin-bottom: 0;
    border-bottom: unset;
  }
  .nav-item {
    position: relative;
    background-color: #fff;
    // border-radius: 5px 5px 0px 0px;
    margin-right: 4px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateX(4px);
      background-color: #eee;
      z-index: -1;
    }
    &:last-child {
      margin-right: 0;
      &::before {
        transform: translateX(0);
      }
    }
    border-bottom: unset;
    .nav-link {
      color: #9e9e9e;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      &.active {
        color: #3967bf;
        border: unset;
        border-bottom: 2.5px solid;
      }
    }
  }
  &-v2 {
    .nav-item {
      margin-right: 0;
      background-color: #fff;
    }
  }
  & ~ .tab-content {
    .tab-pane {
      background: #ffffff;
      border: 1px solid $line-stroke;
      padding: 20px;
    }
  }
}
.fl-breadcrumb-v2 {
  i {
    color: #92a2bc;
  }
}

.sec-field {
  margin-bottom: 4px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #9e9e9e;
    margin: 0;
  }
}
.input-group-text {
  border: unset;
  font-size: 0.9rem;
}

.result-filter-d-owner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
.close-filter-d-owner {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #8a8989;
  border-top-right-radius: 10px;
  border: 1px solid #e4e8f1;
  margin-bottom: 10px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  i {
    color: #464646;
  }
  white-space: nowrap;
}

.close-filter-d-owner:last-child {
  margin-right: unset !important;
}
.btn-scan-camera {
  border: 1px solid #eeeeee;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  i {
    margin-right: 7px;
  }
}
.table-colom {
  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #424242;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #9e9e9e;
  }

  .main {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: #424242;
    margin-bottom: 0;
  }

  .secondary {
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    color: #9E9E9E;
    margin-bottom: 0;
  }
}

.fl-filter-clear {
  box-shadow: unset;
  background: unset;
  border: unset;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #254e9c;
  padding: 0;
}
.fl-alert-login {
  position: relative;
  background-color: white;
  color: $subtle-black;
  // border: solid 1px #ebb103;
  margin-bottom: 15px;
  animation: alert-loginx 0.5s ease;
  // overflow: hidden;
  transition: all 0.3s ease;
  p {
    animation: alert-login 0.8s ease;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    margin: 0;
    margin-right: 16px;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}

.close-filter-active {
  background: #ffffff;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  padding: 6px 12px;
  border-radius: 16px;
  color: #424242;
  display: flex;
  align-items: center;
  border: 1px solid #EEEEEE;
  i {
    margin: 0;
    color: #424242;
  }
}

.custom-input-field {
  background: #E9ECEF;
  padding: 0.37rem 0.81rem;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;

  p, span {
    margin: 0;
    color: #424242;
  }
}

@keyframes alert-login {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes alert-loginx {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
//tambah css di atas sini
//media responsive

@media (max-width: 608px) {
  .cl-m-hide {
    display: none;
  }
}

@media (max-width: 570px) {
  .status-label {
    display: none;
  }
  .status-label-m {
    display: flex;
    justify-content: end;
  }
  .fl_backdrop-m.filter {
    display: block;
    opacity: 0.7;
  }
  .delete-cont {
    position: unset;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    .fl-btn-delete {
      margin-left: auto;
    }
  }
  .card-produk.m-no-scroll {
    overflow: unset;
    height: unset;
    .input-table {
      width: 100%;
    }
  }
  .mobile-filter {
    display: block;
  }
  .result-filter-m {
    display: flex;
  }

  .fl_action {
    --bs-gutter-x: 1.5rem;
  }
  .fl_filter_limit {
    width: 100%;
  }
  .btn.fl_btn i ~ span,
  .collapse-filter-m {
    display: none;
  }
  h3,
  h5 {
    font-size: 16px;
    line-height: 19px;
  }
  .form-label,
  .dropdown-toggle,
  .dropdown-item {
    font-size: 12px;
    line-height: 14px;
  }
  .form-control,
  .form-select {
    font-size: 14px;
    line-height: 19px;
  }
  .fl-card {
    border: 0;
    & .card-body:nth-child(1) {
      padding: 0 !important;
    }
  }
  .fl_table {
    th,
    td {
      font-size: 14px !important;
      white-space: nowrap;
    }
  }
  .fl_btn_add_item {
    padding: 15px !important;
    font-size: 14px !important;
  }
  .collapse-filter {
    flex-direction: column-reverse !important;
    flex-wrap: wrap;
    justify-content: end;
  }

  #mobileTable table,
  #mobileTable thead,
  #mobileTable tbody,
  #mobileTable th,
  #mobileTable td,
  #mobileTable tr {
    display: block;
  }
  #mobileTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #mobileTable td {
    border: none;
    position: relative;
    white-space: normal;
    text-align: left;
    padding: 1.5rem 0;
  }
  #mobileTable tr {
    padding: 1rem 0 0 0;
    border-top: solid 1px $line-stroke;
    border-bottom: solid 1px $line-stroke;
  }
  #mobileTable td:before {
    position: absolute;
    top: 12px;
    left: 15px;
    width: 45%;
    padding-right: 10px;
    text-align: left;
    font-weight: bold;
  }
  #mobileTable td:before {
    content: attr(data-title);
    top: 0;
    font-size: 12px;
    left: 0;
  }

  #mobileTablePembelian table,
  #mobileTablePembelian thead,
  #mobileTablePembelian tbody,
  #mobileTablePembelian th,
  #mobileTablePembelian td,
  #mobileTablePembelian tr {
    display: block;
  }
  #mobileTablePembelian thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #mobileTablePembelian td {
    border: none;
    position: relative;
    white-space: normal;
    text-align: left;
    padding: 1.5rem 0;
  }
  #mobileTablePembelian tr {
    padding: 1rem 0 0 0;
    border-top: solid 1px $line-stroke;
    border-bottom: solid 1px $line-stroke;
  }
  #mobileTablePembelian td:before {
    position: absolute;
    top: 12px;
    left: 15px;
    width: 45%;
    padding-right: 10px;
    text-align: left;
    font-weight: bold;
  }
  #mobileTablePembelian td:before {
    content: attr(data-title);
    top: 0;
    font-size: 12px;
    left: 0;
  }
  .fl-tabs::-webkit-scrollbar {
    display: none;
  }
  .fl-tabs {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    overflow-y: hidden;
    flex-wrap: nowrap;
    position: relative;

    .nav-item {
      white-space: nowrap;
    }
    & ~ .tab-content {
      margin-top: 10px;
    }
    & ~ .tab-content .tab-pane {
      padding: 0;
      border: unset;
      input[name='search'] {
        height: 49px;
      }
    }
  }
  .label-section {
    font-weight: 600;
    font-size: 16px;
    color: #9e9e9e;
  
    &-sub {
      @extend .label-section;
      font-size: 14px;
    }
  }
  .fl-card-transfer {
    &__header {
      display: none;
      &_m {
        display: block;
        &_top {
          padding-bottom: 14px;
          border-bottom: 1px solid #e1e1e1;
        }
        &_bot {
          padding: 14px 0;
          border-bottom: 1px solid #e1e1e1;
          div {
            display: flex;
          }
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #9e9e9e;
          }
        }
      }
    }
    &__body {
      &__images {
        margin-right: 10px;
      }
      &__desc {
        h5 {
          font-size: 14px;
        }
        &__received {
          display: block;
        }
      }
    }

    &__footer {
      &__desc {
        display: block;
        &__r {
          text-align: start;
          p {
            font-size: 14px;
            span {
              font-size: 16px;
            }
          }
        }
      }
      &__action {
        button {
          padding: 5px 8px;
          font-size: 12px;
        }
      }
    }
  }
}
@media (max-width: 820px) {
  .fl_menu {
    position: fixed;
    z-index: 99;
    &.hide {
      height: 0;
      width: 0;
    }
  }
  .fl_backdrop {
    display: block;
    opacity: 0.7;
  }
  .fl_content {
    margin-left: 0;
    z-index: -1;
    padding: 85px 10px 10px 10px;
    .card .card-body {
      padding: 15px;
    }
  }
}
