.btn-help-collapse {
  display: none !important;
}
.btn-auth {
  &:hover,
  &:focus {
    background-color: rgb(249, 249, 249);
  }
}
@media (max-width: 615px) {
  .btn-help {
    display: none !important;
    &-collapse {
      display: block !important;
    }
  }
}
