// INDEX OF ANIMATIONS
// This is a port of Den Eden's Animate.css, converted for SASS.
// Source: https://daneden.me/animate/
//
// @import this index into your project.
// Comment out categories below to remove them from your project.
// Or, @import specific partials (e.g. @import "_attention/_bounce.scss";
//
//-----------------------------------------------------------------------

// Always required
@import '_properties';

// Import the animations
@import '_fading-entrances/fading-entrances.scss';
@import '_fading-exits/fading-exits.scss';
@import '_bouncing-entrances/bouncing-entrances.scss';
@import '_bouncing-exits/bouncing-exits.scss';

.text-animate {
  @include bounceInUp($duration: 0.5s, $function: ease);
  &:hover {
    @include bounceInDown($duration: 0.5s, $function: ease);
  }
}

.bounce-in-up {
  @include bounceInUp($duration: 1.5s, $function: ease-out, $count: infinite);
  animation-direction: alternate-reverse;
}
