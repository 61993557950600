//badge color
$red-1: #ffc8c5;
$red-2: #ffa29c;
$red-3: #fe7c74;
$red-4: #ed5047;
$red-5: #cb3a31;
$red-6: #a9271f;
$red-7: #871811;
$red-8: #650d07;
$red-9: #430501;
$red-10: #430501;

$blue-1: #cfdfff;
$blue-2: #aecaff;
$blue-3: #8db4ff;
$blue-4: #6c9eff;
$blue-5: #5182e1;
$blue-6: #3967bf;
$blue-7: #254e9c;
$blue-8: #1a4373;
$blue-9: #083461;

$green-1: #d7ffec;
$green-2: #baffdd;
$green-3: #9cffcf;
$green-4: #87eaba;
$green-5: #6ecd9f;
$green-6: #57b085;
$green-7: #43936c;
$green-8: #317654;
$green-9: #21593e;

$purple-1: #e2beff;
$purple-2: #d094ff;
$purple-3: #bd6aff;
$purple-4: #7400cf;

$yellow-1: #ffe28c;
$yellow-2: #ffd75f;
$yellow-3: #ebb105;
$yellow-4: #c29100;
$yellow-5: #997300;

$grey-1: #eeeeee;
$grey-2: #424242;

$line-stroke: #e4e8f1;

@mixin fl-status-color($bg-color, $color: #fff) {
  background-color: $bg-color;
  p {
    color: $color;
  }
}

.fl_ {
  &status {
    border-radius: 6px;
    padding: 6px;
    width: 100%;
    text-align: center;
    position: relative;
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      margin: 0;
      padding: 0;
    }
    &.use_dot {
      padding: 5px 7.7px 5px 23.5px;
      text-align: right;
      &::after {
        position: absolute;
        content: '';
        margin-top: auto;
        margin-bottom: auto;
        left: 7.6px;
        top: 0;
        bottom: 0;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: $line-stroke;
      }
    }
  }

  //red
  &_red-1 {
    @include fl-status-color($red-1, $red-5);
  }
  &_red-1.use_dot::after {
    background-color: $red-5;
  }

  &_red-2 {
    @include fl-status-color($red-2, $red-5);
  }
  &_red-2.use_dot::after {
    background-color: $red-5;
  }

  &_red-3 {
    @include fl-status-color($red-3);
  }
  &_red-3.use_dot::after {
    background-color: white;
  }

  &_red-4 {
    @include fl-status-color($red-4);
  }
  &_red-4.use_dot::after {
    background-color: white;
  }

  &_red-5 {
    @include fl-status-color($red-5);
  }
  &_red-5.use_dot::after {
    background-color: white;
  }

  &_red-6 {
    @include fl-status-color($red-6);
  }
  &_red-6.use_dot::after {
    background-color: white;
  }

  &_red-7 {
    @include fl-status-color($red-7);
  }
  &_red-7.use_dot::after {
    background-color: white;
  }

  &_red-8 {
    @include fl-status-color($red-8);
  }
  &_red-8.use_dot::after {
    background-color: white;
  }

  &_red-9 {
    @include fl-status-color($red-9);
  }
  &_red-9.use_dot::after {
    background-color: white;
  }

  &_red-10 {
    @include fl-status-color($red-10);
  }
  &_red-10.use_dot::after {
    background-color: white;
  }

  //blue
  &_blue-1 {
    @include fl-status-color($blue-1, $blue-7);
  }
  &_blue-1.use_dot::after {
    background-color: $blue-7;
  }

  &_blue-2 {
    @include fl-status-color($blue-2, $blue-7);
  }
  &_blue-2.use_dot::after {
    background-color: $blue-7;
  }

  &_blue-3 {
    @include fl-status-color($blue-3, $blue-7);
  }
  &_blue-3.use_dot::after {
    background-color: $blue-7;
  }

  &_blue-4 {
    @include fl-status-color($blue-4);
  }
  &_blue-4.use_dot::after {
    background-color: white;
  }

  &_blue-5 {
    @include fl-status-color($blue-5);
  }
  &_blue-5.use_dot::after {
    background-color: white;
  }

  &_blue-6 {
    @include fl-status-color($blue-6);
  }
  &_blue-6.use_dot::after {
    background-color: white;
  }

  &_blue-7 {
    @include fl-status-color($blue-7);
  }
  &_blue-7.use_dot::after {
    background-color: white;
  }

  &_blue-8 {
    @include fl-status-color($blue-8);
  }
  &_blue-8.use_dot::after {
    background-color: white;
  }

  &_blue-9 {
    @include fl-status-color($blue-9);
  }
  &_blue-9.use_dot::after {
    background-color: white;
  }

  //green
  &_green-1 {
    @include fl-status-color($green-1, $green-7);
  }
  &_green-1.use_dot::after {
    background-color: $green-7;
  }

  &_green-2 {
    @include fl-status-color($green-2, $green-7);
  }
  &_green-2.use_dot::after {
    background-color: $green-7;
  }

  &_green-3 {
    @include fl-status-color($green-3, $green-7);
  }
  &_green-3.use_dot::after {
    background-color: $green-7;
  }

  &_green-4 {
    @include fl-status-color($green-4, $green-7);
  }
  &_green-4.use_dot::after {
    background-color: $green-7;
  }

  &_green-5 {
    @include fl-status-color($green-5);
  }
  &_green-5.use_dot::after {
    background-color: white;
  }

  &_green-6 {
    @include fl-status-color($green-6);
  }
  &_green-6.use_dot::after {
    background-color: white;
  }

  &_green-7 {
    @include fl-status-color($green-7);
  }
  &_green-7.use_dot::after {
    background-color: white;
  }

  &_green-8 {
    @include fl-status-color($green-8);
  }
  &_green-8.use_dot::after {
    background-color: white;
  }
  &_green-9 {
    @include fl-status-color($green-9);
  }
  &_green-9.use_dot::after {
    background-color: white;
  }

  //purple
  &_purple-1 {
    @include fl-status-color($purple-1, $purple-4);
  }
  &_purple-1.use_dot::after {
    background-color: $purple-4;
  }

  &_purple-2 {
    @include fl-status-color($purple-2, $purple-4);
  }
  &_purple-2.use_dot::after {
    background-color: $purple-4;
  }

  &_purple-3 {
    @include fl-status-color($purple-3);
  }
  &_purple-3.use_dot::after {
    background-color: white;
  }

  //yellow
  &_yellow-1 {
    @include fl-status-color($yellow-1, $yellow-3);
  }
  &_yellow-1.use_dot::after {
    background-color: $yellow-3;
  }

  &_yellow-2 {
    @include fl-status-color($yellow-2);
  }
  &_yellow-2.use_dot::after {
    background-color: white;
  }

  &_yellow-3 {
    @include fl-status-color($yellow-3);
  }
  &_yellow-3.use_dot::after {
    background-color: white;
  }

  &_yellow-4 {
    @include fl-status-color($yellow-4);
  }
  &_yellow-4.use_dot::after {
    background-color: white;
  }

  &_yellow-5 {
    @include fl-status-color($yellow-5);
  }
  &_yellow-5.use_dot::after {
    background-color: white;
  }

  //grey
  &_grey-1 {
    @include fl-status-color($grey-1, $grey-2);
  }
  &_grey-1.use_dot::after {
    background-color: $grey-2;
  }

  &_light-green {
    @include fl-status-color(white, #212121);
  }
  &_light-green.use_dot::after {
    background-color: $green-6;
  }
}
