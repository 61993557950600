@import '../../assets/styles/variable';

.fl_menu {
  border-right: 1px solid $line-stroke;
  padding: 70px 0 9px 0 !important;
  height: 100%;
  position: fixed;
  overflow: auto;
  background: $white;
  width: 230px;
  transition: all 0.3s;
  &.hide {
    width: 0;
    transition: all 0.3s;
  }
}
.fl_nav {
  list-style: none;
  padding: 0px 20px;
  margin: 0;
  &__item__sub {
    & .fl_nav__link::before {
      position: absolute;
      content: '';
      width: 100%;
      right: 0;
      top: 0;
      height: 100%;
      background: url('../../assets/images/icons/bx-caret-down.svg') no-repeat
        center right;
    }
  }
  &.sub {
    padding-right: 0px;
  }
  &__link,
  &__link__sub {
    position: relative;
    display: flex;
    align-items: center;
    color: $subtle-black;
    padding: 13px 10px;
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    // line-height: 50px;
    width: 100%;
    // height: 70px;
    i {
      margin-right: 9px;
    }
    &.active span,
    &.active i,
    &.active label {
      color: $blue;
    }
    label {
      cursor: pointer;
    }
    span {
      margin-left: auto;
      font-size: 8px;
      background-color: $red;
      color: white;
      border-radius: 5px;
      padding: 2px;
    }
    &:hover {
      span,
      i,
      label {
        color: $blue;
      }
      color: $blue;
    }
  }
}
button.fl_nav__link {
  border: unset;
  background-color: unset;
}
